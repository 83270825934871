import {
  Button,
  Card,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPortfolio } from "../hooks/use-get-portfolio";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormPortfolio } from "../hooks/use-form-portfolio";
import { PortfolioFormData, PortfolioPayloadType } from "./interface";
import { openNotification } from "../../../utils/helpers";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { convertToSelectValue } from "../../../components/select/function";
import { ValueType } from "../../../components/select/interface";
import { toFileList } from "../../../components/upload-image/function";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import VuiUploadImage from "../../../components/upload-image";
import VuiSelect from "../../../components/select";
import CategoryRepository from "../../../repositories/category-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import RichEditor from "../../../components/rich-editor";

const { TextArea } = Input;
const { Text } = Typography;

const PortfolioFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.portfolio.detail.title")
    : t("pages.portfolio.add.title");
  const { loadPortfolioData, isOnFetchingPortfolioData, portfolioData } =
    useGetPortfolio();
  const { submitLoading, onSubmit, onDelete, deleteLoading } =
    useFormPortfolio();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm<PortfolioFormData>();

  const onFinish = useCallback(
    async (data: PortfolioFormData) => {
      const normalizeData: PortfolioPayloadType = {
        ...data,
        year: data.year.format("YYYY"),
        meta_title: data.meta.meta_title,
        meta_keyword: data.meta.meta_keyword,
        meta_description: data.meta.meta_description,
        category_ids: data.category_ids.map((item) => item.value as number),
        thumbnail_id:
          data.thumbnail_id && data.thumbnail_id.length
            ? (data.thumbnail_id[0].id as number)
            : undefined,
        banner_id:
          data.banner_id && data.banner_id.length
            ? (data.banner_id[0].id as number)
            : undefined,
        image_ids: data.image_ids?.map((photo) => Number(photo.id)),
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.portfolio"),
          })
        );
        navigate(`/portfolio`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadPortfolioData(id, {
          with: [
            "categories",
            "images",
            "thumbnail",
            "banner",
            "meta",
            "creator",
            "editor",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && portfolioData) {
      form.setFieldsValue({
        ...portfolioData,
        year: moment(portfolioData.year),
        category_ids: portfolioData.categories.map(
          (item) => convertToSelectValue(item) as ValueType
        ),
        thumbnail_id: toFileList(portfolioData.thumbnail),
        banner_id: toFileList(portfolioData.banner),
        image_ids: toFileList(portfolioData.images),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/portfolio" />

      <Spin size="large" spinning={isOnFetchingPortfolioData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            is_active: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col md={16} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card title={t("common.text.information")}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="thumbnail_id"
                          tooltip={{
                            title: t("common.text.recommendedSize", {
                              size: "672 x 468",
                            }),
                            icon: <InfoCircleOutlined />,
                          }}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.thumbnail.label"),
                              }),
                            },
                          ]}
                          label={t("common.form.thumbnail.label")}
                        >
                          <VuiUploadImage />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="banner_id"
                          tooltip={{
                            title: t("common.text.recommendedSize", {
                              size: "1867 x 695",
                            }),
                            icon: <InfoCircleOutlined />,
                          }}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.banner.label"),
                              }),
                            },
                          ]}
                          label={t("common.form.banner.label")}
                        >
                          <VuiUploadImage />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="image_ids"
                          label={`${t(
                            "common.form.featuredImage.label"
                          )} (3 Images)`}
                          tooltip={{
                            title: (
                              <Space
                                style={{
                                  width: "100%",
                                }}
                                direction="vertical"
                              >
                                <div>
                                  {t("common.text.recommendedSize", {
                                    size: "672 x 505",
                                  })}{" "}
                                  for first and second
                                </div>
                                <div>
                                  {t("common.text.recommendedSize", {
                                    size: "1376 x 505",
                                  })}{" "}
                                  for the last
                                </div>
                              </Space>
                            ),
                            icon: <InfoCircleOutlined />,
                          }}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.featuredImage.label"),
                              }),
                            },
                            {
                              type: "array",
                              min: 3,
                              message: t("validation.required", {
                                item: t("common.form.featuredImage.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiUploadImage max={3} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="title"
                          label={t("common.form.title.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.title.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.title.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="subtitle"
                          label={t("common.form.subtitle.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.subtitle.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.subtitle.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="customer"
                          label={t("common.form.client.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.client.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.client.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="industry"
                          label={t("common.form.industry.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.industry.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.industry.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="category_ids"
                          label={t("common.form.category.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.category.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            mode="multiple"
                            repository={CategoryRepository}
                            repositoryParams={{
                              types: 94,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="scope_of_works"
                          label={t("common.form.scopeOfWork.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.scopeOfWork.label"),
                              }),
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder={t(
                              "common.form.scopeOfWork.placeholder"
                            )}
                            options={[]}
                            open={false}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={18}>
                        <Form.Item
                          name="link"
                          label={t("common.form.link.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.link.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.link.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="year"
                          label={t("common.form.year.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.year.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiDatePicker picker="year" format="YYYY" />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="description"
                          label={t("common.form.description.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.description.label"),
                              }),
                            },
                          ]}
                        >
                          <RichEditor />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Form.List
                    name="points"
                    rules={[
                      {
                        validator: async (_, points) => {
                          if (!points || points.length < 1) {
                            return Promise.reject(
                              new Error("At least one point is required")
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <Card
                        title={t("common.text.points")}
                        extra={
                          <Button
                            type="primary"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add
                          </Button>
                        }
                      >
                        <Space
                          style={{
                            width: "100%",
                          }}
                          direction="vertical"
                          size={15}
                        >
                          {fields.map(({ key, name, ...restField }) => (
                            <Card
                              title={`${t("common.text.point")} ${name + 1}`}
                              key={key}
                              extra={
                                <>
                                  {name !== 0 ? (
                                    <DeleteOutlined
                                      onClick={() => remove(name)}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              }
                            >
                              <Space
                                direction="vertical"
                                size={15}
                                style={{ width: "100%" }}
                              >
                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "title"]}
                                      label={t("common.text.title")}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: t("common.text.title"),
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: t("common.text.title"),
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "description"]}
                                      label={t("common.text.description")}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: t("common.text.description"),
                                          }),
                                        },
                                      ]}
                                    >
                                      <TextArea
                                        size={"large"}
                                        rows={4}
                                        placeholder={t("common.text.input", {
                                          item: t("common.text.description"),
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Space>
                            </Card>
                          ))}
                        </Space>
                      </Card>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Col>

            <Col md={8} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card title={t("common.text.status")}>
                    <Form.Item name="is_active">
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Not Active</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                </Col>

                <Col xs={24}>
                  <Card title={t("common.text.seo")}>
                    <Form.Item
                      name={["meta", "meta_title"]}
                      label={t("common.form.metaTitle.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaTitle.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.metaTitle.placeholder")}
                      />
                    </Form.Item>

                    <Form.Item
                      name={["meta", "meta_description"]}
                      label={t("common.form.metaDescription.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaDescription.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t(
                          "common.form.metaDescription.placeholder"
                        )}
                      />
                    </Form.Item>

                    <Form.Item
                      name={["meta", "meta_keyword"]}
                      label={t("common.form.metaKeyword.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaKeyword.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.metaKeyword.placeholder")}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/portfolio"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="portfolio.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default PortfolioFormModule;
