import { InvoiceStatus } from "../models/invoice";

export const DEFAULT_PAGE_SIZE = 20;

export const ENV = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
  getNodeEnv: () => window._env_.NODE_ENV,
  getApiEndPoint: () => window._env_.REACT_APP_PASSPORT_END_POINT,
  getPassportClientId: () => window._env_.REACT_APP_PASSPORT_CLIENT_ID,
  getPassportClientSecret: () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET,
  getHostAppUrl: () => window._env_.REACT_APP_URL,
  getAppName: () => window._env_.REACT_APP_NAME,
};

export const LOCALSTORAGE_FILTER_DATA_KEY = {
  year: "filter:year",
  category: "filter:category",
  customer: "filter:customer",
  priority: "filter:priority",
  user: "filter:user",
  industry: "filter:industry",
  projectManager: "filter:project_manager",
  status: "filter:status",
  type: "filter:type",
};

export const MAINTENANCE_STATUS_COLOR: Record<string, string> = {
  "On Going": "#62D88F",
  Expired: "#F44336",
  Cancelled: "#FAAD14",
  "3 Months": "#FAAD14",
  "2 Months": "#FAAD14",
  "1 Month": "#FAAD14",
  "15 Days": "#FAAD14",
  "7 Days": "#FAAD14",
  "3 Days": "#FAAD14",
  "1 Day": "#FAAD14",
};

export const DOMAIN_STATUS_COLOR: Record<string, string> = {
  "On Going": "#62D88F",
  Expired: "#F44336",
  Cancelled: "#FAAD14",
  "3 Months": "#FAAD14",
  "2 Months": "#FAAD14",
  "1 Month": "#FAAD14",
  "15 Days": "#FAAD14",
  "7 Days": "#FAAD14",
  "3 Days": "#FAAD14",
  "1 Day": "#FAAD14",
};

export const INVOICE_STATUS_COLOR: Record<InvoiceStatus, string> = {
  "Waiting For Approval": "#FAAD14",
  Approved: "#0078F0",
  Paid: "#62D88F",
  Due: "#F44336",
  Sent: "#40A9FF",
  Rejected: "#F44336",
};

export const LEAD_STATUS_COLOR: Record<string, string> = {
  OPPORTUNITY: "#0078F0",
  CONTACTED: "#40A9FF",
  MEETING_SCHEDULED: "#40A9FF",
  PROPOSAL_PROGRESS: "#40A9FF",
  SENT: "#40A9FF",
  NEGOTIATION: "#FAAD14",
  WON: "#62D88F",
  LOST: "#F44336",
};

export const MAINTENANCE_CANCEL_ID = 2;
export const DOMAIN_CANCEL_ID = 23;

export enum LEAD_TABS {
  CLIENT = "1",
  LEAD = "2",
}
