import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiButton from "../../../components/button";
import VuiFormItemView from "../../../components/form-item-view";
import VuiPageTitle from "../../../components/page-title";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import ModalExtend from "../components/modal-extend";
import { useFormMaintenance } from "../hooks/use-form-maintenance";
import { useGetMaintenance } from "../hooks/use-get-maintenance";
import { ExtendFormData, ExtendPayloadType } from "./interface";
import { MaintenanceLog } from "../../../models/maintenance-log";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { openNotification } from "../../../utils/helpers";
import ExpiredStatus from "../../../components/expired-status";

const { Text, Title } = Typography;

const MaintenanceDetailModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetMaintenance();
  const title = `${t("common.text.maintenance")} | `;
  const { extendLoading, onExtend, onDelete, deleteLoading } =
    useFormMaintenance();
  const { dispatch } = useBreadcrumb();
  const [showExtendForm, setShowExtendForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const maintenanceLogColumns: ColumnsType<MaintenanceLog> = [
    {
      title: t("common.text.description"),
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Text>
          {record.action !== "Cancel"
            ? `${record.action} (${moment(record.start_date).format(
                "DD MMM YYYY"
              )} - ${moment(record.expired_on).format("DD MMM YYYY")})`
            : `${record.action}`}
        </Text>
      ),
    },
    {
      title: t("common.text.actionDate"),
      dataIndex: "date",
      key: "date",
      render: (text) => <Text>{moment(text).format("DD MMM YYYY")}</Text>,
    },
    {
      title: t("common.text.by"),
      dataIndex: "user_id",
      key: "user_id",
      render: (text, record) => <Text>{record?.user?.name}</Text>,
    },
  ];

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/maintenance",
          title: t("common.text.maintenance"),
        },
        {
          link: `/maintenance/${id}`,
          title: detailData?.project?.code,
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t, detailData?.project?.code, id]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "projectManager",
            "project",
            "status",
            "maintenanceLogs.user",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleEdit = () => {
    navigate(`/maintenance/${id}/edit`);
  };

  const handleExtend = async (formData: ExtendFormData) => {
    const normalizeData: ExtendPayloadType = {
      action: formData?.action.label,
      duration: formData.duration,
    };

    if (id) {
      await onExtend(id, normalizeData);
      setShowExtendForm(false);
      await loadDetailData(id, {
        with: [
          "customer",
          "projectManager",
          "project",
          "status",
          "maintenanceLogs.user",
        ],
      });
    }
  };

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.maintenance"),
          })
        );

        navigate(`/maintenance`);
      });
    }
  }, [id, navigate, onDelete, t]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <VuiPageTitle
            title={
              <Title level={4} style={{ fontWeight: 500, marginBottom: 0 }}>
                {title}
                <Text style={{ fontWeight: 800 }}>
                  {detailData?.project?.code || ""}
                </Text>
              </Title>
            }
            onBackLink="/maintenance"
            rightTitleContent={[
              <ExpiredStatus
                label={detailData?.status.label}
                expiredIn={Number(detailData?.expired_in)}
              />,
            ]}
          >
            <VuiAccessible access="maintenance.update">
              {detailData?.can_update && (
                <Button
                  size="large"
                  type="primary"
                  ghost
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                />
              )}
            </VuiAccessible>
            <VuiAccessible access="maintenance.extend">
              {detailData?.can_extend && (
                <VuiButton
                  title={t("common.button.extend")}
                  buttonProps={{
                    onClick: () => {
                      setShowExtendForm(true);
                    },
                  }}
                />
              )}
            </VuiAccessible>
          </VuiPageTitle>
        </Col>
      </Row>

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card title={t("common.text.generalInformation")}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <VuiFormItemView
                    label={t("common.form.client.label")}
                    value={detailData?.customer?.name}
                  />
                </Col>
                <Col xs={24}>
                  <VuiFormItemView
                    label={t("common.form.description.label")}
                    value={detailData?.description}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={16}>
            <Card title={t("common.text.projectInformation")}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <VuiFormItemView
                    label={t("common.form.idProject.label")}
                    value={detailData?.project?.code}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <VuiFormItemView
                    label={t("common.form.projectManager.label")}
                    value={
                      detailData?.project_manager
                        ? `${detailData.project_manager.name} (${detailData.project_manager.initial})`
                        : ""
                    }
                  />
                </Col>
                <Col xs={24} md={12}>
                  <VuiFormItemView
                    label={t("common.text.start")}
                    value={moment(detailData?.date).format("DD MMM YYYY")}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <VuiFormItemView
                    label={t("common.text.duration")}
                    value={`${detailData?.duration} Month(s)`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Table
              rowKey={"id"}
              columns={maintenanceLogColumns}
              dataSource={detailData?.maintenance_logs || []}
              loading={isOnFetchingDetailData}
              pagination={false}
              size="small"
              scroll={{ x: "max-content" }}
            />
          </Col>

          <VuiAccessible access="maintenance.destroy">
            {detailData?.can_delete && (
              <Col xs={24}>
                <Text
                  className="cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                  title={t("common.text.delete")}
                  type="secondary"
                >
                  {t("common.text.delete")}
                </Text>
              </Col>
            )}
          </VuiAccessible>
        </Row>
      </Spin>

      <ModalExtend
        title={
          <>
            <Title
              level={5}
              style={{
                color: " #979DAD",
                fontWeight: 400,
              }}
            >
              Extend Maintenance
              <br />
              <Title level={4}>{detailData?.project?.code}</Title>
            </Title>
          </>
        }
        show={showExtendForm}
        onClose={() => setShowExtendForm(false)}
        onSubmit={handleExtend}
        submitLoading={extendLoading}
      />
      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default MaintenanceDetailModule;
