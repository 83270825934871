import { Card, Col, Form, Radio, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetInsight } from "../hooks/use-get-insight";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormInsight } from "../hooks/use-form-insight";
import { InsightFormData, InsightPayloadType } from "./interface";
import { openNotification } from "../../../utils/helpers";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { convertToSelectValue } from "../../../components/select/function";
import { ValueType } from "../../../components/select/interface";
import { toFileList } from "../../../components/upload-image/function";
import { InfoCircleOutlined } from "@ant-design/icons";
import VuiUploadImage from "../../../components/upload-image";
import VuiSelect from "../../../components/select";
import CategoryRepository from "../../../repositories/category-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import RichEditor from "../../../components/rich-editor";

const { TextArea } = Input;
const { Text } = Typography;

const InsightFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.insight.detail.title")
    : t("pages.insight.add.title");
  const { loadInsightData, isOnFetchingInsightData, insightData } =
    useGetInsight();
  const { submitLoading, onSubmit, onDelete, deleteLoading } = useFormInsight();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm<InsightFormData>();

  const onFinish = useCallback(
    async (data: InsightFormData) => {
      const normalizeData: InsightPayloadType = {
        ...data,
        date: data.date.format("YYYY-MM-DD"),
        meta_title: data.meta.meta_title,
        meta_keyword: data.meta.meta_keyword,
        meta_description: data.meta.meta_description,
        category_ids: data.category_ids.map((item) => item.value as number),
        thumbnail_id:
          data.thumbnail_id && data.thumbnail_id.length
            ? (data.thumbnail_id[0].id as number)
            : undefined,
        banner_id:
          data.banner_id && data.banner_id.length
            ? (data.banner_id[0].id as number)
            : undefined,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.insight"),
          })
        );
        navigate(`/insight`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadInsightData(id, {
          with: [
            "categories",
            "thumbnail",
            "banner",
            "meta",
            "creator",
            "editor",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && insightData) {
      form.setFieldsValue({
        ...insightData,
        date: moment(insightData.date),
        category_ids: insightData.categories.map(
          (item) => convertToSelectValue(item) as ValueType
        ),
        thumbnail_id: toFileList(insightData.thumbnail),
        banner_id: toFileList(insightData.banner),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/insight" />

      <Spin size="large" spinning={isOnFetchingInsightData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            is_active: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col md={16} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card title={t("common.text.information")}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="thumbnail_id"
                          tooltip={{
                            title: t("common.text.recommendedSize", {
                              size: "442 x 591",
                            }),
                            icon: <InfoCircleOutlined />,
                          }}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.thumbnail.label"),
                              }),
                            },
                          ]}
                          label={t("common.form.thumbnail.label")}
                        >
                          <VuiUploadImage />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="banner_id"
                          tooltip={{
                            title: t("common.text.recommendedSize", {
                              size: "946 x 457",
                            }),
                            icon: <InfoCircleOutlined />,
                          }}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.banner.label"),
                              }),
                            },
                          ]}
                          label={t("common.form.banner.label")}
                        >
                          <VuiUploadImage />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="title"
                          label={t("common.form.title.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.title.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.title.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="category_ids"
                          label={t("common.form.category.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.category.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            mode="multiple"
                            repository={CategoryRepository}
                            repositoryParams={{
                              types: 96,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="date"
                          label={t("common.form.date.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.date.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiDatePicker />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          name="author"
                          label={t("common.form.author.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.author.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("common.form.author.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="content"
                          label={t("common.form.content.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.content.label"),
                              }),
                            },
                          ]}
                        >
                          <RichEditor />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={8} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card title={t("common.text.status")}>
                    <Form.Item name="is_active">
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Not Active</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                </Col>

                <Col xs={24}>
                  <Card title={t("common.text.seo")}>
                    <Form.Item
                      name={["meta", "meta_title"]}
                      label={t("common.form.metaTitle.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaTitle.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.metaTitle.placeholder")}
                      />
                    </Form.Item>

                    <Form.Item
                      name={["meta", "meta_description"]}
                      label={t("common.form.metaDescription.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaDescription.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t(
                          "common.form.metaDescription.placeholder"
                        )}
                      />
                    </Form.Item>

                    <Form.Item
                      name={["meta", "meta_keyword"]}
                      label={t("common.form.metaKeyword.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.metaKeyword.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.metaKeyword.placeholder")}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/insight"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="insight.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default InsightFormModule;
