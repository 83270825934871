import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/helmet";
import ClientLeadDetailModule from "../../../modules/customer/lead/detail";

const ClientLeadDetailPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.lead.detail.metaTitle")} />

      <ClientLeadDetailModule />
    </>
  );
};

export default ClientLeadDetailPage;
