import { Col, Input, Row, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import VuiPageTitle from "../../../components/page-title";
import { useTranslation } from "react-i18next";
import VuiButton from "../../../components/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import { ProductList } from "./interface";
import VuiTableActionButton from "../../../components/table-action-button";
import { useGetProduct } from "../hooks/use-get-product";
import VuiAccessible from "../../../components/accessible";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import {
  overrideTableSortOrder,
  statusDefaultOptions,
  uiFilterData,
} from "../../../utils/helpers";
import useDebounce from "../../../hooks/use-debounce";
import { ValueType } from "../../../components/select/interface";
import {
  DEFAULT_PAGE_SIZE,
  LOCALSTORAGE_FILTER_DATA_KEY,
} from "../../../constants";
import _ from "lodash";
import VuiSelect from "../../../components/select";
import StatusTag from "../../../components/status-tag";

const qs = require("qs-lite");

const ProductListModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loadData, isOnFetchingData, totalData } = useGetProduct();
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<ValueType | undefined>();

  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 250);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tableQuery, setTableQuery] = useState<IPaginationParams>({
    page: Number(searchParams.get("page")) || 1,
    per_page: Number(searchParams.get("perPage")) || DEFAULT_PAGE_SIZE,
    sorted_by: searchParams.get("sortedBy"),
    order_by: searchParams.get("orderBy") as string,
  });

  const handleChangeSearchParams = useCallback(async () => {
    const params = {} as IPaginationParams;

    Object.assign(params, {
      page: tableQuery.page,
    });

    Object.assign(params, {
      perPage: tableQuery.per_page,
    });

    if (debouncedSearch) {
      Object.assign(params, {
        search: debouncedSearch,
      });
    }

    if (tableQuery.order_by) {
      Object.assign(params, {
        orderBy: tableQuery.order_by,
      });
    }

    if (tableQuery.sorted_by) {
      Object.assign(params, {
        sortedBy: tableQuery.sorted_by,
      });
    }

    if (selectedStatus) {
      Object.assign(params, {
        status: selectedStatus.value,
      });
    } else {
      const recentStatus = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.status
      );
      if (!recentStatus) {
        searchParams.delete("status");
      } else {
        const paramsStatusIds = _.map(searchParams.getAll("status"), (item) =>
          parseInt(item)
        );

        if (paramsStatusIds.length) {
          const statusFromStorage = uiFilterData.getSelectedFilter(
            recentStatus,
            paramsStatusIds
          );

          setSelectedStatus(statusFromStorage[0]);

          Object.assign(params, {
            status: statusFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.status);
        }
      }
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("/access");
    }

    const tableParams: IPaginationParams = {
      page: params.page,
      per_page: tableQuery.per_page,
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.order_by ? { order_by: tableQuery.order_by } : {}),
      ...(tableQuery.sorted_by
        ? { sorted_by: overrideTableSortOrder(tableQuery.sorted_by) }
        : {}),
      ...(params.status || params.status === 0
        ? { is_active: params.status }
        : {}),
    };

    await loadData(tableParams);

    setHasInitialize(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableQuery,
    debouncedSearch,
    loadData,
    navigate,
    searchParams,
    selectedStatus,
    setSearchParams,
  ]);

  const columns: ColumnsType<ProductList> = [
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder: tableQuery.order_by === "name" && tableQuery.sorted_by,
    },
    {
      title: t("common.text.status"),
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      defaultSortOrder:
        tableQuery.order_by === "is_active" && tableQuery.sorted_by,
      width: 150,
      render: (status) => <StatusTag active={status} />,
    },
    {
      title: t("common.text.action"),
      key: "id",
      className: "table-action",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        return (
          <VuiTableActionButton
            detailLink={`/other-list/product/${record.id}`}
            detailPermissionName="product.show"
          />
        );
      },
    },
  ];

  const handleOnChangeStatus = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.status, [value]);
    setSelectedStatus(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      status: value,
    }));
  }, []);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      const params: IPaginationParams = {
        page: pagination.current,
        per_page: pagination.pageSize,
      };

      if (sorter.order) {
        Object.assign(params, {
          sorted_by: sorter.order,
          order_by: sorter.field,
        });
      } else {
        Object.assign(params, {
          sorted_by: null,
          order_by: null,
        });
      }

      setTableQuery(params);
    },
    []
  );

  useEffect(() => {
    (async () => {
      await handleChangeSearchParams();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery]);

  useEffect(() => {
    if (hasInitialize) {
      setTableQuery((prevState) => ({
        ...prevState,
        search: debouncedSearch,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <VuiPageTitle title={t("pages.product.list.title")}>
        <VuiAccessible access="product.store">
          <Link to="/other-list/product/add">
            <VuiButton
              title={t("common.button.addItem", {
                item: t("common.text.product"),
              })}
              buttonProps={{
                icon: <PlusOutlined />,
                size: "middle",
              }}
            />
          </Link>
        </VuiAccessible>
      </VuiPageTitle>

      <Row gutter={[10, 10]} className="mb16">
        <Col className="gutter-row" xs={24} md={8}>
          <Input
            allowClear
            placeholder={t("common.form.search.placeholder")}
            prefix={<SearchOutlined />}
            value={search}
            size="middle"
            onChange={(value) => {
              setSearch(value.target.value);
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb16">
        <Col className="gutter-row" xs={24} md={4}>
          <VuiSelect
            defaultOptions={statusDefaultOptions(t)}
            value={selectedStatus}
            placeholder={t("common.text.selectStatus")}
            onChange={handleOnChangeStatus}
            className="select-filter"
            size="middle"
          />
        </Col>
      </Row>

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        loading={isOnFetchingData}
        onChange={handleTableChange}
        pagination={{
          current: tableQuery.page,
          showSizeChanger: true,
          pageSize: tableQuery.per_page,
          total: totalData,
        }}
        size="small"
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default ProductListModule;
