import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const InvoiceRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/invoice`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/invoice/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${ENV.getApiEndPoint()}/api/invoice/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/invoice`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${ENV.getApiEndPoint()}/api/invoice`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  upload: function (id: number | string, payload: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/upload`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.upload.name].handleRequestCancellation()
            .token,
      }
    );
  },
  send: function (id: number | string, payload?: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}/send`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.send.name].handleRequestCancellation()
          .token,
    });
  },
  sendReminder: function (
    id: number | string,
    payload?: any,
    params: any = null
  ) {
    return api.post(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/send-reminder`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.send.name].handleRequestCancellation()
            .token,
      }
    );
  },
  paidOff: function (id: number | string, payload?: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/paid-off`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.paidOff.name
          ].handleRequestCancellation().token,
      }
    );
  },
  download(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/invoice/${id}/download`, {
      params,
      responseType: "blob",
      cancelToken:
        cancelTokenHandlerObject[this.download.name].handleRequestCancellation()
          .token,
    });
  },
  approve: function (id: number | string, payload?: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/approve`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.approve.name
          ].handleRequestCancellation().token,
      }
    );
  },
  reject: function (id: number | string, payload?: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/reject`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.reject.name].handleRequestCancellation()
            .token,
      }
    );
  },
  createRecurringSetting: function (
    id: number | string,
    payload?: any,
    params: any = null
  ) {
    return api.post(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/recurring-setting`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.createRecurringSetting.name
          ].handleRequestCancellation().token,
      }
    );
  },
  updateRecurringSetting: function (
    id: number | string,
    payload?: any,
    params: any = null
  ) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/recurring-setting`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.updateRecurringSetting.name
          ].handleRequestCancellation().token,
      }
    );
  },
  deleteRecurringSetting: function (id: number | string) {
    return api.delete(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/recurring-setting`,
      {
        cancelToken:
          cancelTokenHandlerObject[
            this.deleteRecurringSetting.name
          ].handleRequestCancellation().token,
      }
    );
  },
  exportSalesInvoice(params?: any) {
    return api.post(
      `${ENV.getApiEndPoint()}/api/invoice/export/sales-invoice`,
      {
        ...params,
        responseType: "blob",
      }
    );
  },
  exportSalesPayment(params?: any) {
    return api.post(
      `${ENV.getApiEndPoint()}/api/invoice/export/sales-payment`,
      {
        ...params,
        responseType: "blob",
      }
    );
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(InvoiceRepository);

export default InvoiceRepository;
