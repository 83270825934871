import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InvoiceRepository from "../../../repositories/invoice-repository";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Invoice } from "../../../models/invoice";
import {
  InvoicePayloadType,
  InvoiceRecurringSettingPayloadType,
} from "../form/interface";
import {
  InvoicePaidOffPayloadType,
  InvoiceUploadPayloadType,
} from "../detail/interface";
import fileDownload from "js-file-download";

export const useFormInvoice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [sendReminderLoading, setSendReminderLoading] =
    useState<boolean>(false);
  const [paidOffLoading, setPaidOffLoading] = useState<boolean>(false);
  const [isApprovalSubmitting, setIsApprovalSubmitting] = useState(false);
  const [recurringSettingLoading, setRecurringSettingLoading] =
    useState<boolean>(false);

  const onDelete = useCallback(
    async (id: number | string) => {
      setDeleteLoading(true);

      return new Promise((resolve, reject) => {
        InvoiceRepository.delete(id)
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            reject(e);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      });
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data: InvoicePayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id
        ? InvoiceRepository.update(id, data)
        : InvoiceRepository.create(data)
      )
        .then((response: AxiosResponse<IResource<Invoice>>) => {
          navigate(id ? `/invoice/${id}` : "/invoice");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.invoice"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  const onUpload = useCallback(
    async (id: string, data: InvoiceUploadPayloadType) => {
      setUploadLoading(true);

      await InvoiceRepository.upload(id, data)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.uploadItem`, {
              item: t("common.text.invoice"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setUploadLoading(false);
        });
    },
    [t]
  );

  const onSend = useCallback(
    async (id: string) => {
      setSendLoading(true);

      await InvoiceRepository.send(id)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.sendItem`, {
              item: t("common.text.invoice"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSendLoading(false);
        });
    },
    [t]
  );

  const onSendReminder = useCallback(
    async (id: string) => {
      setSendReminderLoading(true);

      await InvoiceRepository.sendReminder(id)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.sendItem`, {
              item: t("common.text.invoice"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSendReminderLoading(false);
        });
    },
    [t]
  );

  const onSubmitPaidOff = useCallback(
    async (data: InvoicePaidOffPayloadType, id: string) => {
      setPaidOffLoading(true);

      await InvoiceRepository.paidOff(id, data)
        .then((response: AxiosResponse<IResource<Invoice>>) => {
          openNotification(
            "success",
            t(`notification.success.paidOffItem`, {
              item: t("common.text.invoice"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setPaidOffLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  const onApproval = useCallback(
    async (id: string, isApprove: boolean) => {
      setIsApprovalSubmitting(true);
      if (isApprove) {
        await InvoiceRepository.approve(id)
          .then(() => {
            openNotification(
              "success",
              t(`notification.success.approveItem`, {
                item: t("common.text.invoice"),
              })
            );
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          })
          .finally(() => {
            setIsApprovalSubmitting(false);
          });
      } else {
        await InvoiceRepository.reject(id)
          .then(() => {
            openNotification(
              "success",
              t(`notification.success.rejectItem`, {
                item: t("common.text.invoice"),
              })
            );
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          })
          .finally(() => {
            setIsApprovalSubmitting(false);
          });
      }
    },
    [t]
  );

  const onCreateRecurringSetting = useCallback(
    async (id: string | number, data: InvoiceRecurringSettingPayloadType) => {
      setRecurringSettingLoading(true);

      await InvoiceRepository.createRecurringSetting(id, data)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.createItem`, {
              item: t("common.text.recurringSetting"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setRecurringSettingLoading(false);
        });
    },
    [t]
  );

  const onUpdateRecurringSetting = useCallback(
    async (id: string | number, data: InvoiceRecurringSettingPayloadType) => {
      setRecurringSettingLoading(true);

      await InvoiceRepository.updateRecurringSetting(id, data)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.updateItem`, {
              item: t("common.text.recurringSetting"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setRecurringSettingLoading(false);
        });
    },
    [t]
  );

  const onDeleteRecurringSetting = useCallback(
    async (id: string | number) => {
      setRecurringSettingLoading(true);

      await InvoiceRepository.deleteRecurringSetting(id)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.deleteItem`, {
              item: t("common.text.recurringSetting"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setRecurringSettingLoading(false);
        });
    },
    [t]
  );

  const onDownload = useCallback(
    async (id: string, invoiceName: string) => {
      setDownloadLoading(true);

      await InvoiceRepository.download(id)
        .then((response: AxiosResponse) => {
          fileDownload(response.data, `Invoice_${invoiceName}.pdf`);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setDownloadLoading(false);
        });
    },
    [t]
  );

  return {
    onSubmit,
    submitLoading,
    deleteLoading,
    onDelete,
    onUpload,
    uploadLoading,
    onDownload,
    downloadLoading,
    onSend,
    sendLoading,
    paidOffLoading,
    onApproval,
    onSubmitPaidOff,
    isApprovalSubmitting,
    sendReminderLoading,
    onSendReminder,
    onCreateRecurringSetting,
    onUpdateRecurringSetting,
    onDeleteRecurringSetting,
    recurringSettingLoading,
  };
};
