import { Customer } from "./customer";
import { User } from "./user";
import { ProjectType } from "./project-type";
import { GwProduct } from "./gw-product";

export enum ProjectCategoryEnum {
  INTERNAL = "INTERNAL",
  GOOGLE_WORKSPACE = "GOOGLE_WORKSPACE",
}

export type ProjectAssignee = {
  user_id: number;
  user: User;
};

export type Project = {
  id: number;
  customer_id: number;
  project_manager_id: number;
  code: string;
  description: string;
  customer_name: string;
  customer: Customer;
  type_name: string;
  type: ProjectType;
  gw_product: GwProduct;
  project_manager_name: string;
  project_manager: User;
  can_delete: boolean;
  assignees: ProjectAssignee[];
};
