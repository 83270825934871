import { AxiosError } from "axios";
import { notification } from "antd";
import { IconType } from "antd/es/notification";
import _ from "lodash";
import { Permission } from "../../models/permission";
import moment from "moment";
import { TFunction } from "react-i18next";
import { valueType } from "antd/lib/statistic/utils";

export const getAliasesName = (value: string) => {
  try {
    const arrayName = value.split(" ");
    let aliasesName = value.charAt(0) + value.charAt(1);

    if (arrayName.length >= 2) {
      aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
    }

    return aliasesName.toUpperCase();
  } catch (err) {
    return "?";
  }
};

export const openNotification = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
    placement: "top",
  });
};

export const handleBackendError = (e: AxiosError, msg: string = "") => {
  const err = e.response?.data as any;

  if (err && e.response?.status !== 404) {
    Object.keys(err).forEach((key) => {
      let text = msg;
      if (!err[key] || err[key] === "Server Error") {
        text = msg;
      } else {
        text = err[key];
      }

      if (_.isObject(text)) {
        Object.keys(text).forEach((childKey) => {
          openNotification("error", _.get(text, `${childKey}`)[0]);
        });
      } else {
        openNotification("error", text);
      }
    });
  } else {
    openNotification("error", msg);
  }
};

export const canAccess = (access: string | string[], permissions: string[]) => {
  if (typeof access === "string") {
    if (!permissions.includes(access) && access !== "any") {
      return false;
    }
  }

  if (Array.isArray(access)) {
    const tempPermissions: string[] = [];

    access.forEach((permission) => {
      if (permissions.includes(permission)) {
        tempPermissions.push(permission);
      }
    });

    if (tempPermissions.length <= 0) {
      return false;
    }
  }

  return true;
};

export const overrideTableSortOrder = (sortOrder: string) => {
  return sortOrder === "descend" ? "desc" : "asc";
};

export const mapPermissionToTable = (permissions: Permission[]) => {
  const baseData: any = {};
  const tableColumns: any[] = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      className: "text-capitalize",
    },
  ];

  permissions.forEach((permission) => {
    const name = permission.name.split(".")[0];
    if (baseData[name]) {
      baseData[name][permission.method] = permission.id;
    } else {
      Object.assign(baseData, {
        [name]: {
          [permission.method]: permission.id,
        },
      });
    }

    const isExist = tableColumns.find((item) => item.key === permission.method);

    if (!isExist) {
      tableColumns.push({
        title: permission.name.split(".")[1].replaceAll("-", " "),
        dataIndex: permission.method,
        key: permission.method,
        className: "text-capitalize",
      });
    }
  });

  const tableData = Object.keys(baseData).map((key) => ({
    name: key.replaceAll("-", " "),
    ...baseData[key],
  }));

  return {
    tableData,
    tableColumns,
  };
};

export const uiFilterData = {
  save: (key: string, val: any) =>
    localStorage.setItem(key, JSON.stringify(val)),
  get: (key: string) => JSON.parse(localStorage.getItem(key) || "[]"),
  remove: (key: string) => localStorage.removeItem(key),
  getSelectedFilter: (filterData: any[], selectedFilter: any[]) =>
    _.filter(filterData, (data) =>
      selectedFilter.includes(data?.id || data?.value)
    ),
};

export const formatFrontendDate = (date: string): any => {
  return moment(date).format("DD MMM YYYY");
};

export const formatBackendDate = (date: string): any => {
  return moment(date).format("YYYY-MM-DD");
};

export const statusDefaultOptions = (t: TFunction) => {
  return [
    { id: 1, name: t("common.text.active") },
    { id: 0, name: t("common.text.notActive") },
  ];
};

export const inputNumberThousandSeparator = {
  formatter: (value: valueType | undefined) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  parser: (value: string | undefined) => {
    return value!.replace(/\$\s?|(,*)/g, "");
  },
};

export const hourToMillisecond = (hour: number) => {
  return hour * 60 * 60 * 1000;
};

export const convertErrors = (errors: any) => {
  const result: any = {};

  if (errors) {
    for (const key in errors) {
      const baseName = key.split(".")[0];
      if (!result[baseName]) {
        result[baseName] = [];
      }
      result[baseName].push(...errors[key]);
    }
  }

  return result;
};

export const formatPermissionModuleName = (value: string) => {
  if (value.toLowerCase() === "gw-customer") value = "google-workspace";
  if (value.toLowerCase() === "gw-customer-product")
    value = "google-workspace-product";
  if (value.toLowerCase() === "customer") value = "client";

  return _.startCase(value);
};

export const formatPermissionScopeName = (
  value: string,
  hasScope: boolean
): string => {
  let scope = "All";
  const parts = value.split(".");

  let action = parts[1]
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  if (parts[2]) {
    scope = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);
  }

  if (action === "SendReminder") action = "Send Reminder";
  if (action === "PaidOff") action = "Paid Off";
  if (action === "Can_approve") action = "Can Approve";

  if (action.toLowerCase() === "index") {
    action = `View ${hasScope ? "" : "All"}`;
  } else if (action.toLowerCase() === "show") {
    action = "View Detail";
  } else if (action.toLowerCase() === "store") {
    action = "Create";
  } else if (action.toLowerCase() === "update") {
    action = "Update";
  } else if (action.toLowerCase() === "destroy") {
    action = "Delete";
  }

  if (!hasScope) {
    return action;
  }

  if (scope.toLowerCase() === "group") scope = "Group-Data";
  if (scope.toLowerCase() === "self") scope = "Personal-Data";

  return `${action} ${scope}`;
};

export const formatOtherListModuleName = (value: string): string => {
  if (value === "gw-product") {
    value = "google-workspace";
  }
  value = value.split("-").join(" ");
  value = value.split("_").join("-");
  return _.startCase(value);
};

export const snakeCaseToTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const uppercaseToTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const leadStatusOptions = (t: TFunction) => {
  return [
    { id: "OPPORTUNITY", name: t("common.text.opportunity") },
    { id: "CONTACTED", name: t("common.text.contacted") },
    { id: "MEETING_SCHEDULED", name: t("common.text.meetingScheduled") },
    { id: "PROPOSAL_PROGRESS", name: t("common.text.proposalProgress") },
    { id: "PROPOSAL_SENT", name: t("common.text.proposalSent") },
    { id: "NEGOTIATION", name: t("common.text.negotiation") },
    { id: "WON", name: t("common.text.won") },
    { id: "LOST", name: t("common.text.lost") },
  ];
};

export const filteredLeadStatusOptions = (t: TFunction, included: string[]) => {
  return leadStatusOptions(t).filter((item) => included.includes(item.id));
};

export const gwCustomerBillingCycleOptions = (t: TFunction) => {
  return [
    { id: "FIXED_DATE", name: t("common.text.fixedDate") },
    { id: "ACTIVATION_DATE", name: t("common.text.activationDate") },
  ];
};

export const formatGwCustomerBillingCycleName = (
  t: TFunction,
  value: string
): string => {
  let name = "-";
  if (value === "FIXED_DATE") name = t("common.text.fixedDate");
  if (value === "ACTIVATION_DATE") name = t("common.text.activationDate");
  return name;
};

export const gwCustomerPaymentTypeOptions = (t: TFunction) => {
  return [
    { id: "PREPAID", name: t("common.text.prepaid") },
    { id: "POSTPAID", name: t("common.text.postpaid") },
  ];
};

export const formatGwCustomerPaymentTypeName = (
  t: TFunction,
  value: string
): string => {
  let name = "-";
  if (value === "PREPAID") name = t("common.text.prepaid");
  if (value === "POSTPAID") name = t("common.text.postpaid");
  return name;
};

export const gwCustomerProductTypeOptions = (t: TFunction) => {
  return [
    { id: "SEAT", name: t("common.text.seat") },
    { id: "LICENSE", name: t("common.text.license") },
  ];
};

export const formatGwCustomerProductTypeName = (
  t: TFunction,
  value: string
): string => {
  let name = "-";
  if (value === "SEAT") name = t("common.text.seat");
  if (value === "LICENSE") name = t("common.text.license");
  return name;
};

export const wordCount = (text: string): number => {
  return text.trim().split(/\s+/).length;
};

export const ellipsisByWord = (
  text: string,
  wordCount: number,
  ellipsis: string = "..."
): string => {
  if (!text) return "";

  const words = text.trim().split(/\s+/);
  if (words.length <= wordCount) return text;

  return words.slice(0, wordCount).join(" ") + ellipsis;
};
