import { Layout } from "antd";
import VuiSidebar from "../../components/sidebar";
import React, { PropsWithChildren } from "react";
import { SideMenuLayoutInterface } from "./interface";

const SideMenuLayout = (props: PropsWithChildren<SideMenuLayoutInterface>) => {
  const { children, menus } = props;
  return (
    <Layout hasSider={true}>
      <Layout.Sider breakpoint={"lg"} collapsedWidth={0} width={180}>
        <VuiSidebar className="side-menu" menus={menus} mode="vertical" />
      </Layout.Sider>
      <Layout.Content className="content-menu">{children}</Layout.Content>
    </Layout>
  );
};

export default SideMenuLayout;
