import {
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetInvoice } from "../hooks/use-get-invoice";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormInvoice } from "../hooks/use-form-invoice";
import {
  InvoiceFormData,
  InvoicePayloadType,
  InvoiceProductFormData,
  InvoiceRecurringSettingPayloadType,
} from "./interface";
import {
  ApiSelectValue,
  convertToSelectValue,
} from "../../../components/select/function";
import CustomerRepository from "../../../repositories/customer-repository";
import ProjectRepository from "../../../repositories/project-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import { Customer } from "../../../models/customer";
import { User } from "../../../models/user";
import { DeleteOutlined } from "@ant-design/icons";
import ProductRepository from "../../../repositories/product-repository";
import VuiNumberFormat from "../../../components/number-format";
import { inputNumberThousandSeparator } from "../../../utils/helpers";
import ConstantRepository from "../../../repositories/constant-repository";
import UnitRepository from "../../../repositories/unit-repository";
import { AxiosResponse } from "axios";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { ValueType } from "../../../components/select/interface";
import { useGetFirstData } from "../hooks/use-get-first-data";
import UserRepository from "../../../repositories/user-repository";

const invoiceProductDefaultValue = {
  product_id: null,
  description: "",
  price: 0,
  unit_id: null,
  quantity: 0,
};

const InvoiceFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id
    ? t("pages.invoice.edit.title")
    : t("pages.invoice.request.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetInvoice();
  const {
    data: defaultSent,
    isOnFetchingData: isOnFetchingDataDefaultSent,
    loadData: loadDefaultSent,
  } = useGetFirstData(ConstantRepository);
  const {
    data: defaultTemplate,
    isOnFetchingData: isOnFetchingDataDefaultTemplate,
    loadData: loadDefaultTemplate,
  } = useGetFirstData(ConstantRepository);
  const {
    data: defaultProject,
    isOnFetchingData: isOnFetchingDataDefaultProject,
    loadData: loadDefaultProject,
  } = useGetFirstData(ProjectRepository);
  const { submitLoading, onSubmit } = useFormInvoice();

  const [form] = Form.useForm();

  const watchType = Form.useWatch("type", form);
  const watchProjectId = Form.useWatch("project_id", form);
  const watchIsSendInvoiceEmail = Form.useWatch("is_send_invoice_email", form);
  const watchIsSendInvoiceWhatsapp = Form.useWatch(
    "is_send_invoice_whatsapp",
    form
  );
  const watchHasRecurringSetting = Form.useWatch(
    "have_recurring_setting",
    form
  );
  const repeatUntil = Form.useWatch(["recurring_setting", "end_repeat"], form);
  const canEditRecurring = id ? detailData?.can_edit_recurring : true;

  useEffect(() => {
    if (!id && defaultProject) {
      form.setFieldsValue({
        project_id: {
          label: defaultProject?.code,
          value: defaultProject?.id,
        },
      });
    }
  }, [defaultProject, id]);

  useEffect(() => {
    if (!id && defaultSent) {
      form.setFieldsValue({
        sent_type_id: {
          label: defaultSent?.label,
          value: defaultSent?.id,
        },
      });
    }
  }, [defaultSent, id]);

  useEffect(() => {
    if (!id && defaultTemplate) {
      form.setFieldsValue({
        template_id: {
          label: defaultTemplate?.label,
          value: defaultTemplate?.id,
        },
      });
    }
  }, [defaultTemplate, id]);

  const selectedCustomer = Form.useWatch("customer_id", form);

  const selectedSendType = Form.useWatch("sent_type_id", form);

  const selectedTransactionDate = Form.useWatch("date", form);

  const onFinish = useCallback(
    async (data: InvoiceFormData) => {
      const normalizeData: InvoicePayloadType = {
        customer_id: data.customer_id.value as number,
        project_id: data.project_id.value as number,
        date: moment(data.date).format("YYYY-MM-DD"),
        tempo: data.tempo,
        is_ppn: data.is_ppn,
        billing_address: data.billing_address,
        invoice_products: data.invoice_products
          .filter((item) => !!item.product_id)
          .map((item) => {
            return {
              ...item,
              product_id: item.product_id.value as number,
              unit_id: item.unit_id.value as number,
            };
          }),
        sent_date: data?.sent_date
          ? moment(data?.sent_date).format("YYYY-MM-DD HH:mm")
          : undefined,
        sent_type_id: data.sent_type_id.value as number,
        template_id: data.template_id.value as number,
        is_send_invoice_email: data.is_send_invoice_email,
        is_send_invoice_whatsapp: data.is_send_invoice_whatsapp,
        contact_emails: data.contact_emails,
        contact_whatsapp: data.contact_whatsapp,
        project_manager_id: data?.project_manager_id?.value as number,
        recurring_setting: data?.recurring_setting
          ? ({
              number: data.recurring_setting.repeat_number,
              unit: data.recurring_setting.repeat_unit,
              end_repeat: data.recurring_setting.end_repeat,
              is_forever:
                data.recurring_setting.end_repeat === "REPEAT_FOREVER",
              end_after:
                data.recurring_setting.end_repeat === "END_AFTER"
                  ? data.recurring_setting.end_after
                  : null,
              end_on:
                data.recurring_setting.end_repeat === "END_ON"
                  ? moment(data.recurring_setting.end_on).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : null,
            } as InvoiceRecurringSettingPayloadType)
          : undefined,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const onCustomerChange = (selected: ValueType) => {
    CustomerRepository.show(selected.value as string, {
      with: ["contactEmails", "contactWhatsapp"],
    }).then((response: AxiosResponse<IResource<Customer>>) => {
      const data = response.data.data;
      form.setFieldValue("billing_address", data.billing_address);
      form.setFieldValue(
        "contact_emails",
        data.contact_emails.map((contact) => contact.email)
      );
      form.setFieldValue(
        "contact_whatsapp",
        data.contact_whatsapp.map((contact) => contact.phone)
      );
      form.setFieldValue("is_send_invoice_email", data.is_send_invoice_email);
      form.setFieldValue(
        "is_send_invoice_whatsapp",
        data.is_send_invoice_whatsapp
      );
      // form.setFieldValue("sent_to", data.finance_emails);
    });

    form.resetFields(["project_id", "project_manager_id"]);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "projectManager",
            "project",
            "sentType",
            "invoiceProducts.product",
            "invoiceProducts.unit",
            "invoiceEmails",
            "contactEmails",
            "contactWhatsapp",
            "template",
            "recurringSetting",
          ],
        });
      })();
    } else {
      loadDefaultSent({
        for: "invoice_sent_type",
        search: "manual",
        per_page: 1,
      }).catch();
      loadDefaultProject({
        order_by: "created_at",
        sort_by: "desc",
        per_page: 1,
      }).catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      const invoiceProducts =
        detailData.invoice_products?.map((item) => {
          return {
            ...item,
            product_id: convertToSelectValue(
              item.product || ({} as ApiSelectValue)
            ),
            unit_id: convertToSelectValue(item.unit || ({} as ApiSelectValue)),
          };
        }) || [];
      form.setFieldsValue({
        ...detailData,
        date: moment(detailData.date),
        customer_id: convertToSelectValue(
          detailData.customer || ({} as Customer)
        ),
        project_manager_id: convertToSelectValue(
          detailData.project_manager || ({} as User)
        ),
        sent_type_id: {
          label: detailData?.sent_type?.label,
          value: detailData?.sent_type?.id,
        },
        template_id: {
          label: detailData?.template?.label,
          value: detailData?.template?.id,
        },
        project_id: {
          label: detailData?.project?.code,
          value: detailData?.project?.id,
        },
        sent_date: detailData.sent_date ? moment(detailData.sent_date) : null,
        // sent_to: detailData.invoice_emails?.map((item) => item.email),
        contact_emails: detailData.contact_emails?.map(
          (contact) => contact.email
        ),
        contact_whatsapp: detailData.contact_whatsapp?.map(
          (contact) => contact.phone
        ),
        invoice_products: [
          ...invoiceProducts,
          {
            ...invoiceProductDefaultValue,
          },
        ],
        have_recurring_setting: !!detailData?.recurring_setting,
        recurring_setting: {
          repeat_number: detailData?.recurring_setting?.number,
          repeat_unit: detailData?.recurring_setting?.unit,
          end_repeat: detailData?.recurring_setting?.end_repeat,
          end_after: detailData?.recurring_setting?.end_after,
          end_on: detailData?.recurring_setting?.end_on
            ? moment.unix(Number(detailData?.recurring_setting?.end_on))
            : null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle
        title={title}
        onBackLink={id ? `/invoice/${id}` : "/invoice"}
      />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form
          form={form}
          initialValues={{ date: moment() }}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Card>
            <Row gutter={[62, 16]}>
              <Col xs={24} md={7}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="customer_id"
                      label={t("common.form.client.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.client.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={CustomerRepository}
                        placeholder={t("common.form.client.placeholder")}
                        onChange={onCustomerChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="billing_address"
                      label={t("common.form.billingAddress.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.billingAddress.label"),
                          }),
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={4}
                        size="large"
                        placeholder={t(
                          "common.form.billingAddress.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} md={6}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="date"
                      label={t("common.form.transactionDate.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.transactionDate.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiDatePicker
                        onChange={() => form.resetFields(["sent_date"])}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={16}>
                    <Form.Item
                      name="project_id"
                      label={t("common.form.idProject.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.idProject.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ProjectRepository}
                        placeholder={t("common.form.idProject.placeholder")}
                        labelKey="code"
                        repositoryParams={{
                          customer: selectedCustomer?.value,
                          category: watchType,
                        }}
                        onChange={() => {
                          form.resetFields(["project_manager_id"]);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="tempo"
                      label={t("common.form.top.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.top.label"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={t("common.form.top.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={24}>
                    <Form.Item
                      name="project_manager_id"
                      label={t("common.form.projectManager.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.projectManager.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={UserRepository}
                        placeholder={t(
                          "common.form.projectManager.placeholder"
                        )}
                        repositoryParams={{
                          project: watchProjectId?.value,
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} md={11}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={10}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Form.Item
                          name="sent_type_id"
                          label={t("common.form.sendType.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.sendType.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            repository={ConstantRepository}
                            repositoryParams={{
                              for: "invoice_sent_type",
                            }}
                            labelKey="label"
                            placeholder={t("common.form.sendType.placeholder")}
                          />
                        </Form.Item>
                      </Col>

                      {selectedSendType?.label === "Auto" && (
                        <Col xs={24}>
                          <Form.Item
                            name="sent_date"
                            label={t("common.form.sendDate.label")}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.sendDate.label"),
                                }),
                              },
                            ]}
                          >
                            <VuiDatePicker
                              format={"DD MMM YYYY, HH:mm"}
                              disabledDate={(current) => {
                                let transactionDate = moment(
                                  selectedTransactionDate
                                ).format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current <
                                    moment(transactionDate, "YYYY-MM-DD").add(
                                      2,
                                      "days"
                                    )
                                );
                              }}
                              showTime={{ format: "HH:mm" }}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col xs={24}>
                        <Form.Item
                          name="template_id"
                          label={t("common.form.template.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.template.label"),
                              }),
                            },
                          ]}
                        >
                          <VuiSelect
                            repository={ConstantRepository}
                            repositoryParams={{
                              for: "invoice_template",
                            }}
                            labelKey="label"
                            placeholder={t("common.form.template.placeholder")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={14}>
                    <Row gutter={[16, 24]}>
                      <Col xs={24}>
                        <Form.Item label="Notification Preference" required>
                          <Space>
                            <Form.Item
                              name="is_send_invoice_email"
                              valuePropName="checked"
                            >
                              <Checkbox className="large-checkbox">
                                Email
                              </Checkbox>
                            </Form.Item>
                            <Form.Item
                              name="is_send_invoice_whatsapp"
                              valuePropName="checked"
                            >
                              <Checkbox className="large-checkbox">
                                WhatsApp
                              </Checkbox>
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>

                      {watchIsSendInvoiceEmail && (
                        <Col xs={24}>
                          <Form.Item
                            name="contact_emails"
                            label={t("common.form.sentToEmails.label")}
                            rules={[
                              {
                                required: watchIsSendInvoiceEmail,
                                message: t("validation.required", {
                                  item: t("common.form.sentToEmails.label"),
                                }),
                              },
                            ]}
                          >
                            <Select
                              mode="tags"
                              style={{ width: "100%" }}
                              placeholder={t(
                                "common.form.sentToEmails.placeholder"
                              )}
                              options={[]}
                              open={false}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      )}

                      {watchIsSendInvoiceWhatsapp && (
                        <Col xs={24}>
                          <Form.Item
                            name="contact_whatsapp"
                            label={t("common.form.sentToWhatsapp.label")}
                            rules={[
                              {
                                required: watchIsSendInvoiceWhatsapp,
                                message: t("validation.required", {
                                  item: t("common.form.sentToWhatsapp.label"),
                                }),
                              },
                            ]}
                          >
                            <Select
                              mode="tags"
                              style={{ width: "100%" }}
                              placeholder={t(
                                "common.form.sentToWhatsapp.placeholder"
                              )}
                              options={[]}
                              open={false}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.List
                name={["invoice_products"]}
                initialValue={[
                  {
                    ...invoiceProductDefaultValue,
                  },
                ]}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error(
                            t("validation.required", {
                              item: t("common.text.gift"),
                            })
                          )
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Form.ErrorList errors={errors} />
                    <div className="ant-table-wrapper">
                      <div className="ant-table ant-table-small">
                        <div className="ant-table-container">
                          <div
                            className="ant-table-content"
                            style={{ overflowX: "auto" }}
                          >
                            <table style={{ tableLayout: "auto" }}>
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">Product</th>
                                  <th className="ant-table-cell">
                                    Description
                                  </th>
                                  <th className="ant-table-cell">Qty</th>
                                  <th className="ant-table-cell">Units</th>
                                  <th className="ant-table-cell">
                                    Unit Price (Rp)
                                  </th>
                                  <th className="ant-table-cell">
                                    Amount (Rp)
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                {fields.map(({ key, name, ...restField }) => {
                                  return (
                                    <tr
                                      key={key}
                                      className="ant-table-row ant-table-row-level-0"
                                    >
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          style={{ minWidth: "130px" }}
                                          {...restField}
                                          name={[name, "product_id"]}
                                        >
                                          <VuiSelect
                                            onChange={() => {
                                              if (!fields[name + 1]) {
                                                add({
                                                  ...invoiceProductDefaultValue,
                                                });
                                              }

                                              form.setFieldValue(
                                                [
                                                  `invoice_products`,
                                                  name,
                                                  "unit_id",
                                                ],
                                                null
                                              );
                                            }}
                                            repository={ProductRepository}
                                            placeholder={t(
                                              "common.form.product.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>

                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{ minWidth: "200px" }}
                                          name={[name, "description"]}
                                        >
                                          <Input.TextArea
                                            rows={1}
                                            size="large"
                                            placeholder={t(
                                              "common.form.description.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{
                                            width: "70px",
                                            flex: 1,
                                          }}
                                          name={[name, "quantity"]}
                                        >
                                          <InputNumber
                                            placeholder={t(
                                              "common.form.qty.placeholder"
                                            )}
                                            size="large"
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item shouldUpdate className="mb0">
                                          {() => {
                                            const product = form.getFieldValue([
                                              `invoice_products`,
                                              name,
                                              "product_id",
                                            ]);
                                            return (
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "unit_id"]}
                                                style={{
                                                  minWidth: "100px",
                                                }}
                                              >
                                                <VuiSelect
                                                  repository={UnitRepository}
                                                  placeholder={t(
                                                    "common.form.unit.placeholder"
                                                  )}
                                                  repositoryParams={{
                                                    product: product?.value,
                                                  }}
                                                  disabled={!product?.value}
                                                />
                                              </Form.Item>
                                            );
                                          }}
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{
                                            minWidth: "150px",
                                            flex: 1,
                                          }}
                                          name={[name, "price"]}
                                        >
                                          <InputNumber
                                            placeholder={t(
                                              "common.form.unitPrice.placeholder"
                                            )}
                                            formatter={
                                              inputNumberThousandSeparator.formatter
                                            }
                                            parser={
                                              inputNumberThousandSeparator.parser
                                            }
                                            size="large"
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: 10,
                                          }}
                                        >
                                          <Form.Item
                                            shouldUpdate
                                            className="mb0"
                                            style={{
                                              minWidth: "100px",
                                              flex: 1,
                                            }}
                                          >
                                            {() => {
                                              const qty = form.getFieldValue([
                                                `invoice_products`,
                                                name,
                                                "quantity",
                                              ]);
                                              const price = form.getFieldValue([
                                                `invoice_products`,
                                                name,
                                                "price",
                                              ]);

                                              return (
                                                <VuiNumberFormat
                                                  className={
                                                    "display-flex justify-content-between"
                                                  }
                                                  value={qty * price}
                                                />
                                              );
                                            }}
                                          </Form.Item>
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => remove(name)}
                                          >
                                            <DeleteOutlined
                                              style={{ fontSize: 18 }}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Space>
                )}
              </Form.List>
            </Col>

            <Col xs={24}>
              <div className="text-align-right mt24">
                <Form.Item shouldUpdate>
                  {() => {
                    const invoiceProducts =
                      form.getFieldValue("invoice_products");
                    const subtotal = invoiceProducts
                      .map((item: InvoiceProductFormData) => {
                        const price = item?.price || 0;
                        const qty = item?.quantity || 0;
                        return Number(item?.product_id ? price * qty : 0);
                      })
                      .reduce((total: number, num: number) => total + num);

                    const total = subtotal;

                    return (
                      <div>
                        <Row justify="end">
                          <Col
                            xs={24}
                            lg={8}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <table className="table-summary type-one">
                              <tbody>
                                <tr>
                                  <td style={{ minWidth: 100 }}>
                                    {t("common.text.subtotal")}
                                  </td>
                                  <td style={{ textAlign: "end" }}>
                                    <VuiNumberFormat
                                      value={subtotal}
                                      prefix={"Rp "}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>{t("common.text.total")}</b>
                                  </td>
                                  <td>
                                    <b>
                                      <VuiNumberFormat
                                        value={total}
                                        prefix={"Rp "}
                                      />
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Form.Item>
              </div>
            </Col>

            <Col xs={8}>
              <Card style={{ marginTop: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="have_recurring_setting"
                      label={t("common.text.repeatThisInvoice")}
                      initialValue={false}
                      valuePropName="checked"
                      required
                    >
                      <Checkbox
                        disabled={!!id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldsValue({
                              recurring_setting: {
                                end_repeat: "REPEAT_FOREVER",
                              },
                            });
                          } else {
                            form.resetFields(["recurring_setting"]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {watchHasRecurringSetting && (
                    <>
                      <Col xs={24}>
                        <Form.Item
                          label={t("common.form.repeatEvery.label")}
                          required
                        >
                          <Input.Group compact>
                            <Form.Item
                              name={["recurring_setting", "repeat_number"]}
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.repeatEvery.label"),
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                disabled={!canEditRecurring}
                                placeholder={t(
                                  "common.form.repeatEvery.placeholder"
                                )}
                                style={{
                                  width: "70%",
                                }}
                                className="custom-input-number"
                              />
                            </Form.Item>
                            <Form.Item
                              name={["recurring_setting", "repeat_unit"]}
                              noStyle
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                disabled={!canEditRecurring}
                                placeholder={t("common.form.unit.placeholder")}
                                style={{
                                  width: "30%",
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                                className="custom-select"
                              >
                                <Select.Option value="DAY">Day</Select.Option>
                                <Select.Option value="MONTH">
                                  Month
                                </Select.Option>
                                <Select.Option value="YEAR">Year</Select.Option>
                              </Select>
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          name={["recurring_setting", "end_repeat"]}
                          label={t("common.form.ends.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.ends.label"),
                              }),
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Row gutter={[16, 16]}>
                              <Col xs={24}>
                                <Radio value="REPEAT_FOREVER">Never</Radio>
                              </Col>
                              <Col xs={24}>
                                <Row gutter={[16, 16]} align="middle">
                                  <Col xs={8}>
                                    <Radio value="END_ON">On</Radio>
                                  </Col>
                                  <Col xs={16}>
                                    <Form.Item
                                      name={["recurring_setting", "end_on"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: t("common.form.ends.onDate"),
                                          }),
                                        },
                                      ]}
                                    >
                                      <VuiDatePicker
                                        disabled={
                                          !canEditRecurring ||
                                          repeatUntil != "END_ON"
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                        placeholder={t("common.text.date")}
                                        disabledDate={(current) => {
                                          let transactionDate = moment(
                                            selectedTransactionDate
                                          ).format("YYYY-MM-DD");
                                          return (
                                            current &&
                                            current <
                                              moment(
                                                transactionDate,
                                                "YYYY-MM-DD"
                                              ).add(1, "days")
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={24}>
                                <Row gutter={[16, 16]} align="middle">
                                  <Col xs={8}>
                                    <Radio value="END_AFTER">After</Radio>
                                  </Col>
                                  <Col xs={16}>
                                    <Form.Item
                                      name={["recurring_setting", "end_after"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: t(
                                              "common.form.ends.afterSomeOccurrences"
                                            ),
                                          }),
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        size="large"
                                        disabled={
                                          !canEditRecurring ||
                                          repeatUntil != "END_AFTER"
                                        }
                                        min={1}
                                        placeholder="xx"
                                        controls={false}
                                        addonAfter="Occurences"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <VuiFormActionButton
                cancelBtnLink={id ? `/invoice/${id}` : "/invoice"}
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default InvoiceFormModule;
