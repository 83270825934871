import { Card, Col, Row, Spin } from "antd";
import VuiPageTitle from "../../../../components/page-title";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useTranslation } from "react-i18next";
import VuiFormItemView from "../../../../components/form-item-view";
import { useGetLead } from "../../../lead/hooks/use-get-lead";
import { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";

const ClientLeadDetailModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { loadDetailData, isOnFetchingDetailData, detailData } = useGetLead();

  const date = useMemo(() => {
    return moment(detailData?.date).format("DD MMM YYYY") || "-";
  }, [detailData]);

  const name = useMemo(() => {
    const clientType = detailData?.customer?.type?.label || "-";
    const clientName = detailData?.customer?.name || "-";

    return detailData?.lead_name || `${clientType} ${clientName}`;
  }, [detailData]);

  const emails = useMemo(() => {
    return detailData?.emails?.map((contact) => contact).join(", ");
  }, [detailData]);

  const phones = useMemo(() => {
    return detailData?.phones?.map((contact) => contact).join(", ");
  }, [detailData]);

  const industry = useMemo(() => {
    return detailData?.customer?.industry?.name || "-";
  }, [detailData]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: ["customer.industry", "customer.type"],
        });
      })();
    }
  }, [id]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <VuiPageTitle
            title={
              <Title level={4} style={{ fontWeight: 500, marginBottom: 0 }}>
                {`${t("common.text.leads")} | `}
                <Text style={{ fontWeight: 800 }}>{name}</Text>
              </Title>
            }
            onBackLink="/client"
          />
        </Col>
      </Row>

      <Spin spinning={isOnFetchingDetailData}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col xs={8}>
                  <VuiFormItemView label={t("common.text.date")} value={date} />
                </Col>
                <Col xs={8}>
                  <VuiFormItemView
                    label={t("common.text.client")}
                    value={
                      <Link
                        to={`/lead/${id}`}
                        style={{ cursor: "pointer", color: "#041242" }}
                      >
                        {name}
                      </Link>
                    }
                  />
                </Col>
                <Col xs={8}>
                  <VuiFormItemView
                    label={t("common.text.email")}
                    value={emails}
                  />
                </Col>
                <Col xs={8}>
                  <VuiFormItemView
                    label={t("common.text.phone")}
                    value={phones}
                  />
                </Col>
                <Col xs={8}>
                  <VuiFormItemView
                    label={t("common.text.industry")}
                    value={industry}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ClientLeadDetailModule;
